import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from 'components/Seo';
import { GalleryGrid, GalleryImages, GalleryFilter } from 'components/Gallery';
import Container from 'components/Container';
import HeroSection from 'components/HeroSection';
import { GalleryHeroSectionContent } from 'components/PagesSpecific/Gallery';

const Galeria = () => (
  <React.Fragment>
    <Helmet>
      <body className="beige" />
    </Helmet>
    <SEO title="Galeria" />

    <HeroSection title="Galeria" withImage={false}>
      <GalleryHeroSectionContent />
    </HeroSection>

    <GalleryFilter />

    <Container>
      <GalleryGrid>
        <GalleryImages />
      </GalleryGrid>
    </Container>
  </React.Fragment>
);

export default Galeria;
