import React from 'react';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  margin-bottom: 60px;
`;

const GalleryHeroSectionContent = () => (
  <StyledWrapper>
    <p>
      „Propaganda” i&nbsp;reklama w&nbsp;międzywojennej Polsce przyjmowały głównie formę drukowaną.
      Były to kolorowe plakaty, typograficzne afisze na słupach ogłoszeniowych, płotach i&nbsp;w
      gablotach, całostronicowe, kolorowe i&nbsp;czarno-białe reklamy w&nbsp;ilustrowanych
      periodykach, ogłoszenia drobne i&nbsp;anonsy (dawniej inseraty) oraz druki ulotne (ulotki,
      prospekty oraz pocztówki). W&nbsp;galerii znajdziesz przykłady należące do każdej z&nbsp;tych
      pięciu kategorii. Wybór obejmuje przede wszystkim reklamy zachęcające do kupna produktów
      i&nbsp;usług. Część grafik przedstawionych w&nbsp; galerii jest w&nbsp;domenie publicznej,
      część zaś pochodzi ze zbiorów Muzeum Plakatu w&nbsp;Wilanowie i&nbsp; została udostępniona
      dzięki uprzejmości spadkobierców. Przypominamy, że dalsze wykorzystywanie oznaczonych
      w&nbsp;ten sposób ilustracji stanowi naruszenie praw autorskich.
    </p>
  </StyledWrapper>
);

GalleryHeroSectionContent.propTypes = {};

export default GalleryHeroSectionContent;
